.icon-angle-down {
	width: 19.99px;
	height: 12.26px;
}

.icon-angle-left {
	width: 33px;
	height: 41px;
}

.icon-angle-right {
	width: 33px;
	height: 41px;
}

.icon-arrow-left {
	width: 58.44px;
	height: 35.62px;
}

.icon-arrow-right {
	width: 58.44px;
	height: 35.62px;
}

.icon-cross {
	width: 20.07px;
	height: 19.98px;
}

.icon-envelope {
	width: 24.43px;
	height: 16.22px;
}

.icon-marker {
	width: 17.54px;
	height: 23.1px;
}

.icon-menu {
	width: 24.5px;
	height: 17.48px;
}

.icon-phone {
	width: 22.57px;
	height: 22.57px;
}

.icon-plus {
	width: 20.79px;
	height: 19.46px;
}

.icon-time {
	width: 27px;
	height: 27px;
}

